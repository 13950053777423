import axios, { AxiosInstance, AxiosResponse } from 'axios';

const BASE_URL = window._env_.REACT_APP_HOA_SVC;

// Create an Axios instance
const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
});

// Function to handle GET requests
export const fetchData = async <T>(endpoint: string): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await api.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow so calling component can catch
    }
};

// Function to handle POST requests
export const postData = async <T>(endpoint: string, data: any): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await api.post(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
};

// Function to handle PUT requests
export const putData = async <T>(endpoint: string, data: any): Promise<T> => {
    try {
        const response: AxiosResponse<T> = await api.put(endpoint, data);
        return response.data;
    } catch (error) {
        console.error('Error putting data:', error);
        throw error;
    }
};
