import { BodyVerifyToken } from './auth-api-client';
import { authApi } from './AuthApi';

export const getToken = (): string | null => {
    let token = localStorage.getItem('token');
    if (
        token === null ||
        token === undefined ||
        token === '' ||
        token === 'undefined'
    ) {
        token = sessionStorage.getItem('token');
    }
    if (
        token === null ||
        token === undefined ||
        token === '' ||
        token === 'undefined'
    ) {
        return null;
    }
    return token;
};

export const getUserName = (): string | null => {
    let userName = localStorage.getItem('username');
    if (
        userName === null ||
        userName === undefined ||
        userName === '' ||
        userName === 'undefined'
    ) {
        userName = sessionStorage.getItem('username');
    }
    if (
        userName === null ||
        userName === undefined ||
        userName === '' ||
        userName === 'undefined'
    ) {
        return null;
    }
    return userName;
};

export const getRememberMe = (): string | null => {
    let rememberme = localStorage.getItem('rememberme');
    if (
        rememberme === null ||
        rememberme === undefined ||
        rememberme === '' ||
        rememberme === 'undefined' ||
        rememberme === 'false'
    ) {
        rememberme = sessionStorage.getItem('rememberme');
    }
    if (
        rememberme === null ||
        rememberme === undefined ||
        rememberme === '' ||
        rememberme === 'undefined' ||
        rememberme === 'false'
    ) {
        return null;
    }
    return rememberme;
};

export const clearToken = (): void => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
};

export const clearUserName = (): void => {
    localStorage.removeItem('username');
    sessionStorage.removeItem('username');
};

export const clearRememberMe = (): void => {
    localStorage.removeItem('rememberme');
    sessionStorage.removeItem('rememberme');
};

export const clearSessionStorage = (): void => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('rememberme');
};

export const verifyToken = async (token: string | null): Promise<boolean> => {
    try {
        if (token === null) {
            return false;
        }
        const bodyVerifyToken: BodyVerifyToken = { token };
        const response = await authApi.verifyToken({ bodyVerifyToken });
        return (response.data as { isValid: boolean }).isValid;
    } catch (error) {
        console.error('Token verification failed:', error);
        return false;
    }
};
