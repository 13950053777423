import { DefaultApi, Configuration } from './auth-api-client';

const AUTH_SVC_BASE_URL = window._env_.REACT_APP_AUTH_SVC;

// Initialize the API client
const apiConfig = new Configuration({
    basePath: AUTH_SVC_BASE_URL,
});

export const authApi = new DefaultApi(apiConfig);
